.login-form {
  max-width: 400px;
  width: 100%;
  background-color: #ffffff; /* White background for better visibility */
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1); /* Subtle shadow for depth */
}

@media (max-width: 576px) {
  .login-form {
    padding: 2rem 1rem; /* Increase padding on smaller screens for better touch experience */
  }
}

.errors {
  color: red;
}
